<template>
    <div class="fitnessplandetails">
        <VueLoading :active.sync="showLoadingIndicator" :can-cancel="true"></VueLoading>
        <div class="fitnessplandetails__header">
            <!-- TODO @Marco: Unabhängig ob freies Training oder Workout -> hier soll immer der Name des Trainings angezeigt werden -->
            <h3>{{ getWorkoutName }}</h3>
            <div class="fitnessplandetails__header__date">
                {{ getActivityDate }} · {{ activityTime }} Uhr
            </div>
            <div v-if="currentActivity ? currentActivity._uniqueChallengeId : ''" class="fitnessplandetails__header__badge">
                Challenge
            </div>
            <img :src="activityImageUrl" alt="">
        </div>

        <div class="fitnessplandetails__content">
            <!-- Import-Hinweis -->
            <div class="tracking-hint" v-if="trackedWorkoutFrom > 0">
                Von {{ getNameOfImportSource }} importiert
                <!-- TODO @Marco: Per Klick hierauf soll das Security-Modal ("Sind Sie sicher...?") erscheinen -->
                <img @click="openChangeTrackedDataAlertModal" src="@/assets/ic_16_delete_filled.svg" alt="">
            </div>
            <!-- Trainingsdauer -->
            <div
                class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
                <img src="@/assets/ic_64_stopclock.svg" alt="Stopclock Image">
                <div class="challenge__success__statistics__item__left">
                    <div class="challenge__success__statistics__item__value">
                        {{ durationHours }}<span>h</span>
                        {{ durationMinutes }}<span>m</span>
                        {{ durationSeconds }}<span>s</span>
                    </div>
                    <div class="challenge__success__statistics__item__label">
                        Trainingsdauer
                    </div>
                </div>
            </div>
            <!-- Verbrauchte Energie -->
            <div
                class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
                <img src="@/assets/ic_64_flame.svg" alt="Flame Image">
                <div class="challenge__success__statistics__item__left">
                    <div class="challenge__success__statistics__item__value">
                        {{ energyExpended }}<span>kcal</span>
                    </div>
                    <div class="challenge__success__statistics__item__label">
                        Verbrauchte Energie
                    </div>
                </div>
            </div>
            <!-- Intensität (nur bei Workouts) -->
            <div
                v-if="activityType !== 'Custom/SportsType'"
                class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
                <img src="@/assets/ic_64_power.svg" alt="Flame Image">
                <div class="challenge__success__statistics__item__left">
                    <div class="challenge__success__statistics__item__value">
                        {{ difficultyLevel }}
                    </div>
                    <div class="challenge__success__statistics__item__label">
                        Intensität
                    </div>
                </div>
            </div>
            <!-- Equipment (nur bei Workouts) -->
            <div
                v-if="activityType !== 'Custom/SportsType'"
                class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
                <img src="@/assets/ic_64_workouts.svg" alt="Flame Image">
                <div class="challenge__success__statistics__item__left">
                    <div class="challenge__success__statistics__item__value">
                        {{ equipmentUsed }}
                    </div>
                    <div class="challenge__success__statistics__item__label">
                        Equipment
                    </div>
                </div>
            </div>
            <!-- Distanz (nur bei freien Einheiten) -->
            <div
                v-if="distance > 0"
                class="challenge__success__statistics__item challenge__success__statistics__item--small challenge__success__statistics__item--dark">
                <img src="@/assets/ic_64_distance.svg" alt="Flame Image">
                <div class="challenge__success__statistics__item__left">
                    <div class="challenge__success__statistics__item__value">
                        {{ distance }}<span>km</span>
                    </div>
                    <div class="challenge__success__statistics__item__label">
                        Distanz
                    </div>
                </div>
            </div>

            <!-- Import Fitness Data -->
            <v-btn @click="openTrackedWorkoutsModal" class="button button--outline button--small button--icon">
                <img src="@/assets/ic_16_share.svg" alt="">
                Fitnessdaten importieren
            </v-btn>
        </div>

        <ChangeTrackedDataAlertModal :title="'Vorsicht!'" :message="'Möchtest du wirklich die importierten Daten löschen?'" :agreeText="'Ja, Daten löschen'" :disagreeText="'Daten nicht löschen'"  @acceptChanges=undoImport @closeChangeTrackedDataAlertModal=closeChangeTrackedDataAlertModal>></ChangeTrackedDataAlertModal>
        <TrackedWorkoutsModal v-if="activityDate" :realStartDate="activityDate" :workouts="trackingData" @importWorkout="importWorkout" @closeTrackedWorkoutsModal=closeTrackedWorkoutsModal></TrackedWorkoutsModal>

        <router-link :to="activityUrl" class="button button--primary button--small">
            {{ getButtonText }}</router-link>
    </div>
</template>

<script>

import moment from "moment";
import VueLoading from 'vue-loading-overlay/dist/vue-loading';
// Import stylesheet
import 'vue-loading-overlay/src/css/index.css';
import TrackedWorkoutsModal from "@/components/TrackedWorkoutsModal.vue";
import ChangeTrackedDataAlertModal from "@/components/ChangeTrackedDataAlertModal.vue";
import {analytics} from '@/firebase/index.js';
export default {
    name: 'FitnessplanDetailsActivity',
    components: {ChangeTrackedDataAlertModal, TrackedWorkoutsModal, VueLoading },
    data() {
        return {
            fitnessPlan: {},
            currentActivity: null,
            durationHours: "",
            durationMinutes: "",
            durationSeconds: "",
            distance: "0",
            activityDate: "",
            activityTime: "",
            activityType: "",
            activityUrl: "",
            energyExpended: "",
            showLoadingIndicator: false,
            trackingData: null,
            // tracked from Apple HealthKit = 1, from GoogleFit = 2, not tracked = -1, manual changed tracking data = 0
            trackedWorkoutFrom: null,
            importData: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            let activityGuid = this.$route.query.guid;
            let activityDate = this.$route.query.activityDate;
            let activityTime = this.$route.query.activityTime;
            this.fitnessPlan = this.$store.getters.getFitnessplan;
            this.currentActivity = this.fitnessPlan.startedActivities.find(el => el.guid === activityGuid && el.activityDate === activityDate && el.activityTime === activityTime);
            this.trackedWorkoutFrom = this.currentActivity.trackedWorkoutFrom;
            this.durationHours = Math.floor(this.currentActivity.realDuration / 60);
            this.durationMinutes = Math.floor(this.currentActivity.realDuration % 60);
            this.durationSeconds = Math.round(this.currentActivity.realDuration % 1 * 60);
            this.energyExpended = Math.round(this.currentActivity.energyExpended);
            this.distance = this.currentActivity.distance || 0;
            this.activityType = this.getActivityType();
            if (this.$route.query.importData === "true") {
                this.importData = true;
                this.$nextTick(() => this.openTrackedWorkoutsModal());
            }
            if (this.currentActivity._realStartDate) {
                this.activityDate = moment(this.currentActivity._realStartDate).format('yyyy-MM-DD').toString();
                this.activityTime = moment(this.currentActivity._realStartDate).format('HH:mm').toString();
            }
            else {
                this.activityDate = moment(activityDate).format('yyyy-MM-DD').toString();
                this.activityTime = activityTime;
            }
            this.activityUrl = this.getActivityUrl();
        },
        getActivityType() {
            if (this.currentActivity == null) { return "" }
            if (!(this.currentActivity || this.currentActivity.activity)) {
                return null;
            }
            if (this.currentActivity.activity.meta === undefined) {
                return "Category";
            } else {
                return this.currentActivity.activity.meta.type;
            }
        },
        getActivityUrl() {
            if (this.currentActivity == null) { return "" }
            if (this.importData === true) {
                return "/fitnessplan?date=" + this.activityDate;
            }
            else if (this.activityType === "Custom/SportsType") {
                return "/fitnessplan/customActivity?sportsType_id=" + this.currentActivity.activity.id +
                    "&guid=" + this.currentActivity.guid + "&activityDate=" + this.currentActivity.activityDate + "&activityTime=" + this.currentActivity.activityTime +
                    "&navbartitle=" + this.currentActivity.activity.name;
            }
            else if (this.activityType === "Category") {
                return "/playlist/start?category_id=" + this.currentActivity.activity.id;
            }
            else if (this.activityType === "Custom/UserPlaylist") {
                return "/playlist/start?user_playlist_id=" + this.currentActivity.activity.uid;
            }
            else if (this.activityType === "exercise.PlaylistPage") {
                return "/playlist/start?playlist_id=" + this.currentActivity.activity.id;
            }
            else if (this.activityType === "workout.WorkoutPage") {
                return "/workout/" + this.currentActivity.activity.id;
            }
            else if (this.activityType === "fitnessplan.FitnessPlanExercisePage") {
                return "/fitnessplan/customActivity?" +
                "&guid=" + this.currentActivity.guid + "&activityDate=" + this.currentActivity.activityDate + "&activityTime=" + this.currentActivity.activityTime +
                "&navbartitle=" + this.currentActivity.activity.title;
            }
        },
        importWorkout(workout) {
            this.showLoadingIndicator = true;
            this.importData = true;
            this.durationHours = Math.floor(workout.duration / 3600);
            this.durationMinutes = Math.floor(workout.duration % 3600 / 60);
            this.durationSeconds = Math.round(workout.duration % 60);
            this.energyExpended = Math.round(workout.energy.value);
            this.distance = Math.round(workout.distance.value / 10) / 100;
            this.activityDate = moment(workout.from_utc.split(" ")[0], 'yyyy-MM-DD').format('yyyy-MM-DD').toString();
            this.activityTime = moment(workout.from_utc.split(" ")[1], 'HH:mm').format('HH:mm').toString();
            this.trackedWorkoutFrom = workout.import_source;
            this.activityUrl = this.getActivityUrl();
            this.completeFitnessPlanEvent(workout)
        },
        async completeFitnessPlanEvent(workout) {
            if (this.currentActivity) {
                if(!this.currentActivity.trackedWorkoutFrom || this.currentActivity.trackedWorkoutFrom < 1) {
                    this.currentActivity.trackedWorkoutFrom = this.trackedWorkoutFrom;
                    this.currentActivity._realDuration = this.currentActivity.realDuration;
                    this.currentActivity._energyExpended = this.currentActivity.energyExpended;
                    this.currentActivity._distance = this.currentActivity.distance;
                    this.currentActivity._realStartDateOld = moment(this.currentActivity.activityDate + ' ' + this.currentActivity.activityTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                }
                this.currentActivity.realDuration = workout.duration / 60;
                this.currentActivity.energyExpended = workout.energy.value;
                this.currentActivity._realStartDate = moment(this.currentActivity._realStartDate, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD').toString();
                this.currentActivity._realStartDate = moment(this.activityDate + ' ' + this.activityTime,'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                await this.updateFitnessPlan();
                this.showLoadingIndicator = false;
            }
        },
        async updateFitnessPlan() {
            await this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
        },
        async undoImport() {
            this.closeChangeTrackedDataAlertModal();
            this.showLoadingIndicator = true;
            this.currentActivity.realDuration = this.currentActivity._realDuration;
            this.currentActivity.energyExpended = this.currentActivity._energyExpended;
            this.currentActivity._realStartDate = moment(this.currentActivity._realStartDateOld, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
            this.currentActivity.distance = this.currentActivity._distance;
            this.trackedWorkoutFrom = -1
            this.currentActivity.trackedWorkoutFrom = this.trackedWorkoutFrom;
            this.durationHours = Math.floor(this.currentActivity.realDuration / 60);
            this.durationMinutes = Math.floor(this.currentActivity.realDuration % 60);
            this.durationSeconds = Math.round(this.currentActivity.realDuration % 1 * 60);
            this.energyExpended = Math.round(this.currentActivity.energyExpended);
            this.distance = this.currentActivity.distance;
            this.activityDate = moment(this.currentActivity._realStartDate).format('yyyy-MM-DD').toString()
            this.activityTime = moment(this.currentActivity._realStartDate).format('HH:mm').toString();
            await this.updateFitnessPlan();
            this.showLoadingIndicator = false;
        },
        openTrackedWorkoutsModal() {
            this.$modal.show("trackedWorkouts");
            analytics.logEvent('try_import_of_tracking_data');
        },
        closeTrackedWorkoutsModal() {
            this.$modal.hide("trackedWorkouts");
        },
        openChangeTrackedDataAlertModal() {
            this.$modal.show("changeTrackedDataAlert");
        },
        closeChangeTrackedDataAlertModal() {
            this.$modal.hide("changeTrackedDataAlert");
        },
        goBack() {
            this.$router.push("/fitnessplan?date=" + this.activityDate);
        },
    },
    computed: {
        activityImageUrl() {
            if (this.currentActivity == null) { return "" }
            if (this.activityType === 'Custom/UserPlaylist') {
                return 'https://app.sportlerplus.com/scaled/800/bruce-mars-gJtDg6WfMlQ-unsplash.jpg';
            }
            if (this.activityType === 'workout.WorkoutPage') {
                return 'https://app.sportlerplus.com/scaled/800/' + this.currentActivity.activity.preview_image_url;
            }
            else return 'https://app.sportlerplus.com/scaled/800/' + this.currentActivity.activity.image_url;
        },
        equipmentUsed() {
            if (this.currentActivity == null) { return "" }
            if (this.currentActivity.activity.requires_equipment) {
                return "Ja"
            }
            else return "Nein"
        },
        difficultyLevel() {
            if (this.currentActivity == null) { return "" }
            if (this.currentActivity.activity.difficulty === 3) {
                return "Hoch";
            }
            else if (this.currentActivity.activity.difficulty === 2) {
                return "Mittel";
            }
            else return "Leicht";
        },
        getWorkoutName() {
            if(this.activityType) {
                if (this.activityType === "workout.WorkoutPage" || this.activityType === "fitnessplan.FitnessPlanExercisePage") {
                    return this.currentActivity.activity.title;
                }
                else {
                    return this.currentActivity.activity.name;
                }
            }
            return "";
        },
        getNameOfImportSource() {
            if (this.trackedWorkoutFrom === 1) {
                return "Apple HealthKit"
            }
            else {
                return "GoogleFit"
            }
        },
        getActivityDate() {
            return moment(this.activityDate).locale('de').format('DD. MMM yyyy').toString();
        },
        getButtonText() {
            if (this.activityType === "Custom/SportsType" || this.activityType === "fitnessplan.FitnessPlanExercisePage") {
                //Für individuelle Einheit
                return "Leistungsdaten ändern"
            }
            else {
                //Für Workouts
                if (this.importData) {
                    // mit importierten tracking Daten
                    return "Änderungen übernehmen"
                }
                else {
                    // ohne importierten tracking Daten
                    return "Workout erneut starten"
                }
            }
        }
    }
}
</script>

<style lang="scss">

    .fitnessplandetails {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        .workout__item {
            margin-top: -12px;
            border-radius: 4px;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            margin: -24px -20px 0;
            text-align: center;
            padding: 45px 0 calc(34px + 80px);
            background: $colorBlack;

            @media screen and (min-width: 520px) and (max-width: 767px) {
                width: 100vw;
                margin: -24px calc((-100vw + 480px) / 2) 0;
            }

            @media screen and (min-width: 768px) {
                width: calc(100% + 144px + 144px);
                margin: -24px -144px 0;
            }

            h3 {
                position: relative;
                z-index: 1;
                line-height: 24px;
                font-weight: 900;
                color: $colorWhite;
                text-transform: uppercase;
                text-shadow: 0 2px 16px rgba($colorBlack, 0.48);
            }

            &__date {
                position: relative;
                z-index: 1;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.21px;
                color: $colorWhite;
                margin-top: 7px;
            }

            &__badge {
                position: relative;
                z-index: 1;
                display: inline-flex;
                font-size: 11px;
                line-height: 11px;
                font-weight: 700;
                letter-spacing: -0.13px;
                text-transform: uppercase;
                padding: 4px 5px 3px 6px;
                margin-top: 10px;
                margin-bottom: 6px;
                border-radius: 2px;
                color: $colorWhite;
                background: $colorPrimary;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                opacity: .5;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            border-radius: 8px;
            box-shadow: 0 0 0 1px rgba($colorBlack, .08), 0 8px 16px 0 rgba($colorBlack, .04);
            overflow: hidden;
            margin-top: -80px;
            margin-bottom: 32px;
            background: $colorWhite;

            /* OVERWRITE */
            .challenge__success__statistics__item {
                cursor: inherit;
                background: $colorWhite;
                margin-bottom: 0;
                border-radius: 0;
                margin-bottom: 4px;

                &:not(:nth-last-child(-n+2)) {
                    box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
                    margin-bottom: 0;
                }
            }

            .button {
                box-sizing: border-box;
                margin: 16px;
                width: calc(100% - 32px);
                font-size: 14px !important;
            }
        }

        .button {
            margin-top: auto;
        }
    }

    .tracking-hint {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($colorPrimary, .08);
        box-shadow: 0 1px 0 0 $colorPrimary;
        font-size: 14px;
        line-height: 10px;
        font-weight: 700;
        color: $colorPrimary;
        padding: 19px 0;
        margin-bottom: 1px;

        img {
            position: absolute;
            right: 0;
            padding: 16px;
            cursor: pointer;
        }
    }
</style>